@import "config.scss";

.btn-blue,.btn-green,.btn-four,.btn-delete,.btn-one,.btn-white,.btn-two,.btn-three,.btn-blue,.btn-white-blue,.btn-green-white,.btn-grey,.btn-red{
    background: map-get($colors,five);
    display: flex;
    padding: 20px 10px;
    border-radius: 5px;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    border: 0;
    cursor: pointer;
    -webkit-transition: background 500ms ease;
    -moz-transition: background 500ms ease;
    -ms-transition: background 500ms ease;
    -o-transition: background 500ms ease;
    transition: background 500ms ease;
}
.btn-one{  
    background: map-get($colors,one);
    color: #fff;
    border-radius: 10px;
}
.btn-four{
    background: map-get($colors,one);
}
.btn-green{
    background: #379b0a;
}
.btn-delete{
    background: #b4232d;
}
.btn-two{  
    background: map-get($colors,two);
    color: #fff;
}
.icon-left .icon{
    margin: 0 7px 0 0;
}
.icon-right .icon{
    margin: 0 0 0 7px;
}
.btn-icon .icon{
    margin: 0 5px 0 0;
}
/** btn custom **/
.btn-custom{
    background: #FFF;
    border-radius: 30px;
    display: flex;
    border: 0;
    padding: 20px 30px;
    -webkit-box-shadow: 0 0 10px 0 #D6D6D6;
    box-shadow: 0 0 10px 0 #D6D6D6;
    cursor: pointer;
    border: 1px solid #c4c4c4;
}
.btn-reserve{
    background: #ff852c !important;
    display: flex;
    border: 0 !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 5px 0px 0px #EC6A0B, 0px 20px 20px -10px rgba(0, 0, 0, 0.7), inset 0px 0px 20px 0px #EC6A0B;
}
.btn-reserve2{
    background: #38930f !important;
    display: flex;
    border: 0 !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    color: #FFF;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 5px 0px 0px #46a91a, 0px 20px 20px -10px rgba(0, 0, 0, 0.7), inset 0px 0px 20px 0px #46a91a;
}
.btn-reserve .icon{
    margin: 0 10px 0 0;
}
.btn-reserve span{
    font-weight: 600;
}
