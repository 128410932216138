@import "./config.scss";

footer h3.modulo-title{
    color: map-get($colors,one);
    font-size: 14px;
    font-family: map-get($fonts,three);
    margin: 0 0 10px 0;
    font-weight: 900;
}
.contato-item .icon{
    background: map-get($colors,nine);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 20px 0 0;
}
.contato-item .icon i{
    font-size: 18px;
    color: #FFF;
}
.contato-item{
    display: flex;
    cursor: pointer;
    -webkit-transition: background 300ms ease;
    -moz-transition: background 300ms ease;
    -ms-transition: background 300ms ease;
    -o-transition: background 300ms ease;
    transition: background 300ms ease;
}
.contato-item strong{
    font-size: 13px;
    margin: 0 0 20px 0;
    margin: 0 0 20px 0;
    color: map-get($colors,nine); 
    margin: 0 0 10px 0;
    display: table;
}
.contato-item span{
    display: table;
    font-size: 14px; 
    font-weight: 300;
}
.contato-item .content{
    flex: 1;
    display: flex;
    align-items: center;
}
.contato-item .arrow{
    background: map-get($colors,one);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    margin: 10px;
}
.bg-orange{
    background: #da590b !important;
}
.color-orange{
    color: #da590b !important; 
}
.bg-purple{
    background: #8108d6 !important;
}
.color-purple{
    color: #8108d6 !important;
}

@media (max-width: 959px){


}
@media (max-width: 767px){

    footer .modulo{
        margin: 0 0 20px 0 !important;
    }
    .contato-item{
        margin: 0 0 20px 0;
    }

}